.button {
    padding: var(--spacing-small) var(--spacing-small);
}

.button_no-padding {
    padding: var(--spacing-small) 0;
}

.button__highlighted_top {
    position: absolute;
    height: var(--spacing-border);
    width: var(--spacing-big);
    margin-top: calc(-1 * var(--spacing-tiny));
    transform: translate(16%, 0);
    background-color: var(--color-important);
}

.button__highlighted_bottom {
    position: absolute;
    height: var(--spacing-border);
    width: var(--spacing-big);
    margin-top: var(--spacing-tiny);
    transform: translate(16%, 0);
    background-color: var(--color-important);
}
