.accounts {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.accounts__list {
    padding-top: var(--spacing-small);
}

.account_item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.account_item__description {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--spacing-tiny) 0 var(--spacing-tiny) var(--spacing-small);
    color: var(--color-title);
    font-size: var(--font-large);
}

.account_item__created-time {
    color: var(--color-primary);
    font-size: var(--font-small);
    padding-top: var(--spacing-tiny);
}
