.settings {
    width: 360px;
    display: flex;
}

.settings__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 var(--spacing-medium);
    border: var(--spacing-border) solid var(--color-background-light);
    background-color: var(--color-background);
}

.settings__multi-row {
    display: flex;
    flex-direction: column;
}

.settings__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.settings__label {
    padding: 0 var(--spacing-small);
}

.settings__system {
    padding-top: var(--spacing-small);
    background-color: var(--color-background-light);
    margin: 0 var(--spacing-negative);
}
