.exchanges {
    min-height: var(--spacing-genormus);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--spacing-tiny);
}

.exchanges__prices {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.exchanges__prices__time-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

.exchanges__prices__value-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-width: 160px;
}

.exchanges__prices__row {
    display: flex;
    flex-direction: row;
    padding: 0 var(--spacing-small);
}

.exchanges__prices__row-of-interest {
    display: flex;
    flex-direction: row;
    padding: 0 var(--spacing-small);
    background: var(--color-background-light);
    border-radius: 10px;
}

.exchanges__prices__time {
    padding-bottom: var(--spacing-tiny);
}

.exchanges__prices__info {
    display: flex;
    justify-content: center;
    color: var(--color-background-light);
    font-size: var(--font-small);
}

.exchanges__prices__exchange {
    min-width: 220px;
    color: var(--color-title);
}
