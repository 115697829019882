#root {
  display: flex;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  background-color: var(--color-background-light);
}

.app__container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-border) 0px;
}

.app__header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-opposite);
  padding: 0px var(--spacing-small);
  background-color: var(--color-title);
  background-size: cover;
  border: 1px solid var(--color-opposite);
}

.app__header-content {
  max-width: 1400px;
  min-height: 70px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-primary);
}

.license_locked {
  color: var(--color-error);
  font-size: var(--font-small);
  text-align: center;
}

.app__content {
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: var(--font-medium);
  color: var(--color-primary);
  background-color: var(--color-background-content);
}
.app__error {
  width: 360px;
}
.content__devider {
  margin: var(--spacing-small) 0 var(--spacing-small) 0;
  height: var(--spacing-atomic);
  background-color: var(--color-background-light);
}

.content__splitter {
  margin: var(--spacing-small) 0;
  height: var(--spacing-atomic);
  background-color: var(--color-primary);
}

.web-module__widget {
  margin: var(--spacing-border) var(--spacing-border);
}

.web-module__inner-widget {
  margin: var(--spacing-tiny) 0 var(--spacing-tiny) 0;
}

.blink {
  animation: blink-animation 3s steps(5, start) infinite;
  -webkit-animation: blink-animation 3s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

:root {
  --color-primary: white;
  --color-opposite: black;
  --color-background: #170D0DFF;
  --color-background-light: rgb(45, 47, 42);
  --color-background-dark: rgb(245,247,247);
  --color-background-content: black;
  --color-title: rgb(84,93,62);
  --color-important: rgb(156,39,176);
  --color-error: red;
  --color-warning: rgb(250,180,180);

  --spacing-negative: -8px;
  --spacing-atomic: 1px;
  --spacing-border: 2px;
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-big: 48px;
  --spacing-huge: 64px;
  --spacing-genormus: 125px;

  --font-tiny: 10px;
  --font-small: 12px;
  --font-medium: 16px;
  --font-large: 20px;
  --font-huge: 28px;

  --font-bold: 700;
}

