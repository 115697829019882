.icon-button {
    padding: var(--spacing-small) var(--spacing-small);
    color: var(--color-background-light);
}

.icon-button_no-padding {
    padding: 0 0;
    color: var(--color-background-light);
}

.icon-button__action {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-background-light);
    border-radius: 50%;
    padding: var(--spacing-tiny) var(--spacing-tiny);
}
