.login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background-light);
}

.login__circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    background-color: var(--color-background);
    z-index: 0;
}

.login__circle-background {
    background-size: cover;
    background-color: var(--color-background-light);
    border: var(--spacing-border) solid var(--color-title);
    position: absolute;
    height: 270px;
    width: 270px;
    border-radius: 50%;
    z-index: 1;
}

.login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: var(--spacing-border) solid var(--color-title);
    background-color: transparent;
    z-index: 2;
}

.login__title {
    font-size: var(--font-huge);
    font-weight: var(--font-bold);
    color: var(--color-title);
    padding: var(--spacing-medium);
}
