.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: var(--spacing-border) solid var(--color-error);
    border-radius: 10%;
    margin: var(--spacing-medium) 0;
    padding: var(--spacing-small);
    background-color: var(--color-background);
}

.error__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-small) var(--spacing-small) var(--spacing-small) 0;
    color: var(--color-error);
    font-size: var(--font-large);
    font-weight: var(--font-bold);
}

.error__text {
    padding-bottom: var(--spacing-medium);
}

.error__actions {
    display: flex;
    flex-direction: row;
}
