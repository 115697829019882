.header {
    display: flex;
    flex-direction: column;
    margin-left: -8px;
    margin-right: -8px;
}

.header__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: var(--spacing-small);
}

.header__no-back {
    height: var(--spacing-big);
}

.header__title {
    display: flex;
    flex: 1;
    padding-left: var(--spacing-tiny);
}
