.twitter-rule {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.twitter-rule__update-key-words {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-small);
}
