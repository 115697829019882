.notifications {
    min-height: var(--spacing-genormus);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--spacing-tiny);
}

.notifications__delete {
    position: fixed;
    bottom: 0px;
    margin-left: var(--spacing-genormus);
    margin-bottom: var(--spacing-small);
}

.notifications__entry {
    display: flex;
    flex-direction: column;
}

.notifications__entry__text {
    padding: var(--spacing-small) 0px;
    color: var(--color-title);
}

.notifications__entry__state {
    margin-left: auto;
}
