.price-threshold-rule {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.price-threshold-rule__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.price-threshold-rule__field__threshold {
    width: 150px;
}

.price-threshold-rule__update {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-small);
}
