.account-details {
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.account-details__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-small);
}

.account-details__value {
    padding: var(--spacing-small) 0;
    color: var(--color-title);
}
