.account-edit {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.account-edit__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-small);
}
