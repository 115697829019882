.dashboard {
    width: 360px;
    display: flex;
    flex-direction: column;
}

.dashboard__toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: var(--spacing-small) 0;
    background-color: var(--color-background-light);
}

.dashboard__content {
    flex: 1;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-medium);
    border: var(--spacing-border) solid var(--color-background-light);
    background-color: var(--color-background);
}

