.add-new-rule {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--color-primary);
}

.add-new-rule__rule {
    padding: var(--spacing-tiny) var(--spacing-tiny);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-new-rule__action {
    font-size: var(--font-large);
    color: var(--color-primary);
}

.add-new-rule__rule-description {
    display: flex;
    font-size: var(--font-small);
    color: var(--color-title);
}
